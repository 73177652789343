import React from 'react'
import { InstitucionalPage } from '@monorepo-amais/commons/components/Institucional'
import { graphql } from 'gatsby'

import Layout from './../components/layout'

const Institucional = ({ location, data }) => (
	<Layout footer={data.footer} regionais={data.regionais}>
		<InstitucionalPage allCosmicjsInstitucional={data.allCosmicjsInstitucional} location={location}></InstitucionalPage>
	</Layout>
)

export const query = graphql`
	query {
		allCosmicjsInstitucional {
			nodes {
				metadata {
					seo {
						description
						title
						image {
							imgix_url
							url
						}
						imageAlt
					}
				}
			}
			edges {
				node {
					slug
					title
					content
					metadata {
						icon {
							imgix_url
							url
						}
						image {
							imgix_url
							url
						}
					}
				}
			}
		}
	}
`

export default Institucional
