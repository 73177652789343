import React from 'react'
import styled, { css } from 'styled-components'
import { TabPanel } from 'react-web-tabs'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const title = css`
	color: ${props => props.theme.colors.black_fonts};
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};
	}
`

const SubTitle = styled.h2`
	${title}
	font-size: 18px;
	margin: 0 0 30px 0;
	@media (min-width: 768px) {
		font-size: 22px;
	}
`

const Image = styled.img`
	width: 100%;
	height: 200px;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	object-fit: cover;
`

const Content = styled.div`
	p {
		font-size: 15px;
		line-height: 20px;
		color: ${props => props.theme.colors.gray66};
	}
	a {
		color: ${props => props.theme.colors.primary};
		text-decoration: none;
		line-height: 20px;
		font-weight: 900;
	}
	strong {
		font-weight: 900;
	}
	h1 {
		${title}
		font-size: 22px;
	}
`

const Institucional = props => (
	<TabPanel tabId={props.tabId}>
		<SubTitle> {props.title} </SubTitle>
		<Image src={imgixUrlOptimizerAuto(props.imgUrl, undefined, 200)} alt={props.imgTitle} />
		<Content dangerouslySetInnerHTML={{ __html: props.content }} />
	</TabPanel>
)

export default Institucional
