/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'
import { TabProvider, Tab, TabList } from 'react-web-tabs'
import styled from 'styled-components'

import SEO from '../seo'

import { Institucional } from './'
import { BreadcrumbAuto } from '../Blocks'
import { MainTitle } from '../Typography'
import queryString from 'query-string'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const MainContainer = styled.div`
	max-width: 1360px;
	padding: 0;
	margin: 0 auto;
	@media (min-width: 1024px) {
		padding: 0 20px;
	}
`

const MainContent = styled.div`
	margin-bottom: 20px;

	@media (max-width: 1023.98px) {
		align-items: center;
		padding: 10px 0;
		.tabList {
			display: flex;
			justify-content: space-between;
			padding: 12px 0;
			background: ${props => props.theme.colors.grayf9};
			margin-bottom: 20px;
		}
		.content {
			padding: 0 15px;
		}
	}
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: 0.35fr 1fr;
		grid-gap: 20px;
		margin-bottom: 30px;
		grid-template-rows: auto;
		grid-template-areas: 'sidebar content';
		.sidebar {
			grid-area: sidebar;
		}
		.content {
			grid-area: content;
			display: flex;
			flex-direction: column;
			padding-left: 20px;
			border-left: 1px solid ${props => props.theme.colors.light_gray};
		}
	}
`
const Aba = styled(Tab)`
	width: 100%;
	padding: 30px 0;
	background: none;
	border: none;
	outline: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 100%;

	:not(:last-child) {
		@media (min-width: 992px) {
			border-bottom: 1px solid ${props => props.theme.colors.light_gray};
		}
	}
	cursor: pointer;
	.icon {
		margin-right: 20px;
		width: 46.7px;
	}
	span {
		font-weight: 900;
		font-size: 22px;
		color: ${props => props.theme.colors.black_fonts};
		-webkit-transition: all 0.2s ease-in-out;
		-moz-transition: all 0.2s ease-in-out;
		-ms-transition: all 0.2s ease-in-out;
		-o-transition: all 0.2s ease-in-out;
		transition: all 0.2s ease-in-out;
	}
	&[aria-selected='true'] {
		span {
			color: ${props => props.theme.colors.primary};
		}
	}
	&:hover {
		span {
			color: ${props => props.theme.colors.primary};
		}
	}
	&:last-child {
		border: none;
	}
	@media (max-width: 1023.98px) {
		position: relative;
		width: auto;
		display: flex;
		flex-direction: column;
		padding: 0 10px;
		border-bottom: 0;
		border-right: 1px solid ${props => props.theme.colors.light_gray};
		justify-content: center;

		.icon {
			max-width: 30px;
			margin-right: 0;
			margin-bottom: 5px;
			max-height: 30px;
		}
		span {
			font-size: 10px;
			word-break: break-word;
		}
	}
`

const InstitucionalPage = props => {
	const [defaultTabSlug, setDefaultTabSlub] = useState('sobre')

	useEffect(() => {
		let queryParam = queryString.parse(props.location.search).session

		if (!queryParam) queryParam = ''

		if (queryParam !== '') {
			setDefaultTabSlub(queryParam)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const tabDefault = props.allCosmicjsInstitucional.edges.filter(item =>
		item.node.slug.toLowerCase().includes(defaultTabSlug)
	)

	return (
		<div>
			{props.allCosmicjsInstitucional.nodes.map((title, index) => (
				<SEO
					key={`seo-${index}`}
					title={title.metadata.seo.title}
					description={title.metadata.seo.description}
					image={imgixUrlOptimizerAuto(title.metadata.seo.image.imgix_url)}
				/>
			))}
			<MainContainer>
				<BreadcrumbAuto title='Institucional' />
				<MainTitle> Institucional </MainTitle>

				<MainContent>
					<TabProvider defaultTab={tabDefault.length > 0 ? tabDefault[0].node.slug : ''}>
						<div className='sidebar'>
							<TabList className='tabList'>
								{props.allCosmicjsInstitucional.edges.map(icones => (
									<>
										<Aba tabFor={icones.node.slug} style={{ fontFamily: 'fs_lolaregular' }}>
											<img src={imgixUrlOptimizerAuto(icones.node.metadata.icon.imgix_url)} className='icon' />
											<span>{icones.node.title}</span>
										</Aba>
									</>
								))}
							</TabList>
						</div>
						<div className='content'>
							{props.allCosmicjsInstitucional.edges.map((img, index) => (
								<Institucional
									key={`institucional-component-${index}`}
									tabId={img.node.slug}
									title={img.node.title}
									imgUrl={imgixUrlOptimizerAuto(img.node.metadata.image.imgix_url)}
									imgTitle='Titulo'
									content={img.node.content}
								/>
							))}
						</div>
					</TabProvider>
				</MainContent>
			</MainContainer>
		</div>
	)
}

export default InstitucionalPage
